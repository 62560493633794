/**
 * Installations
 * Installations
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 1 = Owner, 10 = User, 53 = TechnicalSupport
 */
export enum InstallationRoles {
    Owner = 'owner',
    User = 'user',
    TechnicalSupport = 'technical_support'
}

