/**
 * Installations
 * Installations
 *
 * The version of the OpenAPI document: 1.0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 0 = InstallationInfoRead, 1 = InstallationInfoWrite, 2 = InstallationDelete, 3 = InstallationProductsRead, 4 = InstallationProductsUpdate, 5 = InstallationProductsDelete, 6 = InstallationScenesRead, 7 = InstallationScenesWrite, 8 = InstallationRoutinesRead, 9 = InstallationRoutinesWrite, 10 = InstallationSecurityRead, 11 = InstallationSecurityWrite, 12 = InstallationMetadataRead, 13 = InstallationMetadataWrite, 14 = InstallationDiagnostics, 15 = InstallationDebug
 */
export enum InstallationPermissions {
    InstallationInfoRead = 'installation_info_read',
    InstallationInfoWrite = 'installation_info_write',
    InstallationDelete = 'installation_delete',
    InstallationProductsRead = 'installation_products_read',
    InstallationProductsUpdate = 'installation_products_update',
    InstallationProductsDelete = 'installation_products_delete',
    InstallationScenesRead = 'installation_scenes_read',
    InstallationScenesWrite = 'installation_scenes_write',
    InstallationRoutinesRead = 'installation_routines_read',
    InstallationRoutinesWrite = 'installation_routines_write',
    InstallationSecurityRead = 'installation_security_read',
    InstallationSecurityWrite = 'installation_security_write',
    InstallationMetadataRead = 'installation_metadata_read',
    InstallationMetadataWrite = 'installation_metadata_write',
    InstallationDiagnostics = 'installation_diagnostics',
    InstallationDebug = 'installation_debug'
}

